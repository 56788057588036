.contentPanel {

  //background: white;
  padding: 1rem 0;
  //text-align: center;
  position: relative;
  word-break: break-word;

  >* {
    z-index: 1;
    position: relative;
  }

  h2 .button {
    font-size: 2.6rem;
  }

  h3 .button {
    font-size: 2.2rem;
  }

  h4 .button {
    font-size: 2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }

  a.button {
    color: white;
    background: $primary-color;
    padding: 0.2rem 1rem;
    border-radius: 30px;
    padding-bottom: 0.34rem;

    &:focus,
    &:hover {
      background: $link-hover-color;
      text-decoration: none;
    }
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}

.contentPanel.multiColumnPanel {
  div.container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: unset !important;
    padding-right: unset !important;
  }

  .container.contact-wrapper.contact__form,
  .container.contact-wrapper.compreshensiveAssessmentForm {
    background-color: #464646;
    border-radius: 17px;
    .form-control.is-invalid, .form-control.is-invalid::placeholder {
      color: white;
    }
    select.form-control.is-invalid option {
      color: black;
    }
  }

  .contactForm {
    min-height: 555px;
  }
}