.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &.bannerHasTitlePanel::before {
    content: '';
    background: rgba(0,0,0,0.3333);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
}

.banner_item__text {
  text-align: center;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
  z-index: 10;
  font-family: $family-base;
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem; 
  line-height: 1.1;
  
  @include media-breakpoint-up(lg) {
    font-size: 2.3rem;
    margin-bottom: -0.3rem;
  }
}

.banner-item__link {
  font-size: 1rem; 
  font-weight: 400;
  display: inline-block;
  padding: 0.4rem 1rem;
  background-color: $primary-color;
  color: #fff;
  margin-top: 1rem;

  @include media-breakpoint-up(lg) {
  }

  &:hover, &:focus {
    color: #fff; 
    background-color: $link-hover-color;
    text-decoration: none;
  }
}

.bannerItem__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.banner-item .htmlPanel {
  top: 57%;
  @include media-breakpoint-up(sm){
    top: 50%;
  }
  transform: translateY(-50%);
  position: relative;
  max-width: 350px;
  margin: auto;
  @include media-breakpoint-up(sm){
    max-width: 1300px;
  }
  img{
    //max-width: 13%;
    max-width: 11%;
    height: auto;
    display: unset;
    @include media-breakpoint-up(sm){
      max-width: 100%;
    }
  }
  h1, h2, h3, h4, h5, p{
    //margin-top: 1rem;
    //margin-bottom: 1rem;
    //line-height: 1.1;
    //line-height: 0.9;
    line-height: 1;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h1 {
    //font-size: rem(50, $font-size-mobile);
    font-size: rem(42, $font-size-mobile);
    padding-bottom: 0.25rem;
    @include media-breakpoint-up(sm){
      margin-top: -6px;
    }
  }
  
  h2 {
    //font-size: rem(35, $font-size-mobile);
    font-size: rem(27, $font-size-mobile);
  }
  
  h3 {
    //font-size: rem(30, $font-size-mobile);
    font-size: rem(22, $font-size-mobile);
    padding-bottom: 0.5rem;
    @include media-breakpoint-down(xs){
      max-width: 290px;
      margin: auto;
    }
  }
  
  h4 {
    //font-size: rem(25, $font-size-mobile);
    font-size: rem(18, $font-size-mobile);
  }
  
  h5 {
    //font-size: rem(19, $font-size-mobile);
    font-size: rem(16, $font-size-mobile);
  }

  p{
    padding: 0.5rem 0px;
    font-size: rem(16, $font-size-mobile);
  }

  @include media-breakpoint-up(sm){
    h1 {
      font-size: rem(120, $font-size-mid-desktop);
    }
  
    h2 {
      font-size: rem(60, $font-size-mid-desktop);
    }
  
    h3 {
      font-size: rem(40, $font-size-mid-desktop);
    }
  
    h4 {
      font-size: rem(30, $font-size-mid-desktop);
    }
  
    h5 {
      font-size: rem(22, $font-size-mid-desktop);
    }
  
  }

  @include media-breakpoint-up(lg) {

    h1 {
      font-size: rem(160, $font-size-desktop);
    }
  
    h2 {
      font-size: rem(80, $font-size-desktop);
    }
  
    h3 {
      font-size: rem(48, $font-size-desktop);
    }
  
    h4 {
      font-size: rem(36, $font-size-desktop);
    }
  
    h5 {
      font-size: rem(24, $font-size-desktop);
    }
  }


}

.banner-item.content::before{
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0,0,0,.3);
}