// overriding bootstrap variables in _boostrap-override.scss

$family-base: "acumin-pro-semi-condensed",sans-serif;
$family-header: $family-base;
$family-header2: "acumin-pro",sans-serif;

// font-size
$font-size-desktop: 24px;

$font-size-mid-desktop: 22px;
$font-size-mobile: 19px; 

$font-size-desktop-main: 20px;
$font-size-mobile-main: 18px;

$weight-light: 200;
$weight-light-normal: 300;
$weight-regular: 400;
$weight-bold: 600;
$weight-normal: $weight-light-normal;

// colors - sister colours from bootrap
$color-blue:    #30588c;
$color-red:     #c20f2f;
$color-orange:  #fd7e14;
$color-green:   #3599a5;

// more colors
$color-gold:  #faa222;
$color-dark-gray: #252525;
$color-gray: #666666;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-green;
$font-color: $color-gray;
$font-color-white: #fff;
$header-color: $font-color;
$bg-color:#fff;
$link-color: $primary-color;
$link-hover-color: $color-blue;

// top-bar
$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-height: (mobile: 2.4rem, tablet : 2rem, desktop: 2.4rem);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;