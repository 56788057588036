
.cmsPage {
  &--BlogPost {
    .contentPanel:not(.blogCommonBottom) .htmlPanel {
      text-align: left;
      //max-width: 700px;
      // h2 {
      //   font-size: 2rem;
      // }

      @include media-breakpoint-up(lg) {
        // h2 {
        //   font-size: 2.6rem;
        // }
      
      }
      // img {
      //   width: 100%;
      // }
    }

    .blogCommonBottom {

    }
    
    .bottomPanel .htmlPanel {
      text-align: center;
    }

    .instagram-media {
      max-width: 720px !important;
    }
    .element-invisible {
      display:none;
    }
  }
}

.blogView__titleContainer{
  max-width: 960px;
  margin: auto;
  .blogView__title{
    font-weight: $weight-bold;
    color: $color-green;
  }
}