$breakpoints: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 3,
);
$margin: 20px;

.blogTiles {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &__loadMore {
    margin-bottom: 2rem;
    .thinBtn {
      width: 100%;
      display: block;
      color: white;
      background-color: $font-color;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $font-color;
        background-color: white;
      }
    }
  }
}

.blogTile__readMore__container {
  position: absolute;
  top: 0px;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.blogTile {
  padding: $margin/2;
  overflow: hidden;
  @each $bp, $number in $breakpoints {
    @include media-breakpoint-up(#{$bp}) {
      flex: 0 0 100% / $number;
      max-width: 100% / $number;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background: #fff;
    color: $font-color;
    box-shadow: 1px 1px 5px 0px #e0e0e0;
    line-height: 1.1;
    text-align: left;
    padding: 8px;

    &:hover,
    &:focus {
      color: $font-color;
      text-decoration: none;
      .thinBtn {
        //color: white;
        //background-color: $font-color;
        //display: block;
      }
      .blogTile__readMore__container {
        background-color: rgba(0, 0, 0, 0.6);
        left: 0px;
      }
    }
  }

  &__bg {
    @include bg-img();
    @include img-ratio(70%);
    width: 100%;
    position: relative;
    overflow: hidden;
    //z-index: -1;
    //margin-bottom: 90px;
  }

  &__content {
    width: 100%;
    //position: absolute;
    left: 0;
    bottom: 0;
  }

  &__contentInner {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 15px;
    //margin: 30px 30px 10px 30px;
  }

  &__title {
    font-family: $family-header;
    font-weight: bold;
    //font-size: 1.5rem;
    font-size: 1rem;
    //min-height: 3.1rem;
    text-transform: uppercase;
    line-height: 1;
    margin: 0.5rem 0;
    text-align: center;
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }

  &__desc {
    font-size: 0.8rem;
    height: 3.5rem;
    line-height: 1.5;
    width: 100%;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 0.6rem 0;
  }
}

.thinBtn {
  // background-color: $primary-color;
  // border-radius: 0;
  // color: white;
  // font-size: 0.8rem;
  // letter-spacing: 1px;
  // transition: all 0.15s;
  // margin: auto;
  // font-weight: 700;
  // width: 140px;
  // padding: 1rem;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
}
.blogTiles .blogTile__readMore:hover .tile_icon {
  display: none;
}
.blogTiles .blogTile__readMore:hover .tile_icon_hover {
  display: block;
}
.blogTile__readMore img {
  width: 15%;
}
.blogTile__readMore .tile_icon {
  display: block;
}
.blogTile__readMore .tile_icon_hover {
  display: none;
}
