.tiles4 .slick-slider {
  width: 100% !important;
  height: auto !important;
}
.tile4.useSlider {
  flex-basis: 100%;
  max-width: 100%;
}
.tile4__link {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  background: #fff;
  color: #666666;
  box-shadow: 1px 1px 5px 0px #e0e0e0;
  line-height: 1.1;
  text-align: left;
  padding: 8px;
  &:hover,
  &:focus {
    color: $font-color;
    text-decoration: none;
    .pageTile4__readMore__container {
      background-color: rgba(0, 0, 0, 0.6);
      left: 0px;
    }
  }
}
.pageTile4__readMore__container {
  position: absolute;
  top: 0px;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}
.tile4__link:hover,
.tile4__link:focus {
  color: #666666;
  text-decoration: none;
}
.tile4__bg {
  @include bg-img();
  @include img-ratio(70%);
  width: 100%;
  position: relative;
  overflow: hidden;
}
.pageTile4__content {
  width: 100%;
}
.pageTile4__contentInner {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 15px;
}
.pageTile4__title {
  font-family: $family-header;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1;
  margin: 0.5rem 0;
  text-align: center;
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}
.pageTile4__desc {
  font-size: 0.8rem;
  height: 3.5rem;
  line-height: 1.5;
  width: 100%;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 0.6rem 0;
}
.tile4 .pageTile4__readMore:hover .tile_icon {
  display: none;
}
.tile4 .pageTile4__readMore:hover .tile_icon_hover {
  display: block;
}
.pageTile4__readMore img {
  width: 15%;
}
.pageTile4__readMore .tile_icon {
  display: block;
}
.pageTile4__readMore .tile_icon_hover {
  display: none;
}
