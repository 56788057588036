%bannerSize--standard {
  //height: 350px;
  //height: 450px;
  height: 323px;

  @include media-breakpoint-up(sm) { // 576px and up
    //height: 350px;
    //height: 500px;
    height: 400px;
  }
  @include media-breakpoint-up(md) { // 768px and
    //height: 400px;
    //height: 600px;
    height: 445px;
  }
  @include media-breakpoint-up(lg) { // 992px and up
    //height: 450px;
    //height: 600px;
    height: 445px;
  }
  @include media-breakpoint-up(xl) { // 1200px and up
    //height: 500px;
    //height: 600px;
    height: 445px;
  }
  @media (min-width: 1601px) {
    //height: 550px;
    //height: 600px;
    height: 445px;
  }
  @media (min-width: 1901px) {
    //height: 600px;
    //height: 600px;
    height: 445px;
  }
  @media (min-width: 2201px) {
    //height: 600px;
    height: 445px;
  }
}