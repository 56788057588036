.contactForm {
  max-width: 800px;
  margin: 1.5rem auto;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid $color-green;
  border-radius: 15px;
}

div.form-row {
  div.form-title {
    background-color: $color-green;
    text-align: center;
    border-radius: 15px;
    margin-left: -1.05rem;
    margin-right: -1.05rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    color: white;
    font-weight: 600;
  }
}

.compreshensiveAssessmentForm {
  .form-check{
    //display: inline-block;
    flex: 1 0 auto;
  }
  .check__container{
    display: flex;
  }
  .form-check-input{
    width: 20px;
    height: 20px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: unset;
    margin-left: unset;
  }
  .form-check-label {
    color: white;
    font-size: 0.9rem;
  }
  .text-col{
    color: white;
    margin-bottom: 0px !important;
  }
  p{
    color: white;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.3;
  }
}

// .contactForm__container{

// }