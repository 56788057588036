@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";
@import "./modules/rotatingTextBanner";
@import "./modules/accordingPanel";

// components
@import "../components";

@import "./site/index";

.slick-slide.slick-current {
  z-index: 2;
}

.container {
  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
}

div.appInfo {
  //text-align: center;
  max-width: 960px;
  margin: auto;
}

.curve {
  position: absolute;
  width: 100%;

  svg {
    width: 100%;
    height: 70px;
    @include media-breakpoint-down(md) {
      height: 50px;
    }
    @include media-breakpoint-down(xs) {
      height: 30px;
    }
  }
}

.top-curve {
  //top: -1px;
  top: -2px;
  &:after {
    background-size: contain;
    background-position: center;
    height: 50px;
    //background-image: url(/assets/freshgarlic-small.png);
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 70px;
    display: inline-block;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.bottom-curve {
  //bottom: 0;
  bottom: -1px;
}

.multiColumnPanel {
  max-width: 960px;
  margin: auto;
  .container {
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(xl) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

div.bannerPanel {
  @include media-breakpoint-down(xs) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }
}

div.grecaptcha-badge {
  z-index: 101;
}

div.recapthca-container > div > div > div {
  margin: auto;
}

@media screen and (max-width: 1100px) {
  .grecaptcha-badge {
    display: none !important;
  }
}
