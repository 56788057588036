.container {
  max-width: 1400px;
}
.container:has(> .tiles3) {
  max-width: 1400px;
}
.tiles3 {
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
}
.cms_item.tiles3 .tile3 {
  flex: 0 0 24%;
  // max-width: 25%;

  @media screen and (max-width: 1110px) {
    flex: 0 0 48%;
    // max-width: 100%;
  }

  @media screen and (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.tile3 .pageTile__content.tile__content {
  position: relative;
  overflow: hidden;
}
.tile3 .pageTile_details {
  padding: 1rem 1.5rem;
  position: absolute;
  top: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
}

.tiles .tile3 .tile__bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tile3 .pageTile__content.tile__content:hover .pageTile__title_bg,
.tile3 .pageTile__content.tile__content:active .pageTile__title_bg {
  display: none;
}

.tile3 .pageTile__content.tile__content:hover .pageTile_details,
.tile3 .pageTile__content.tile__content:active .pageTile_details {
  opacity: 1;
  transform: translateY(0);
  // position: absolute;
  // top: 0;
  // left: 0;
}
.tiles3 .tile3 .tile__bg,
.tile3 .pageTile_details {
  width: 100%;
  height: 300px;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 340px;
    padding: 16px;
  }
}
.tile3 .pageTile__content:hover .pageTile_details,
.tile3 .pageTile__content:focus .pageTile_details {
  background: rgba(17, 25, 33, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.tile3 .pageTile__content:hover .pageTile__title,
.tile3 .pageTile__content:hover .pageTile_desc,
.tile3 .pageTile__content:active .pageTile__title,
.tile3 .pageTile__content:active .pageTile_desc,
.tile3 .pageTile__content:focus .pageTile__title,
.tile3 .pageTile__content:focus .pageTile_desc {
  color: #fff;
}
.tile3 .tile_button {
  // font-size: 0.8rem;
  // font-weight: 400;
  // background-color: $primary-color;
  // color: #fff;
  // margin-top: 1rem;
  // padding: 0.2rem 1rem;
  // border-radius: 30px;
  // padding-bottom: 0.34rem;
  // &:hover,
  // &:focus {
  //   color: #fff;
  //   background-color: $link-hover-color;
  //   text-decoration: none;
  // }
}
.tile3 .tile_button:hover .tile_icon {
  display: none;
}
.tile3 .tile_button:hover .tile_icon_hover {
  display: block;
}
.tile3 .tile_button img {
  width: 15%;
}
.tile3 .tile_icon {
  display: block;
}
.tile3 .tile_icon_hover {
  display: none;
}
.tile3 .pageTile__title {
  font-size: 1.2rem;
  margin: 1rem 0;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    margin: 1.3rem 0;
  }
}
.tile3 .pageTile_desc {
  line-height: 1.2;
  font-size: 16px;
  margin-bottom: 1rem;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    line-height: 1.4;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}
.tile3 .pageTile__title_bg {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  padding: 1.5rem 1.2rem 0.8rem;
  font-size: 1.2rem;
  line-height: 1.2;
  color: white;
  height: 30%;
  font-family: $family-base;
  font-weight: $weight-bold;
  background: linear-gradient(180deg, transparent 0, #000);
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
