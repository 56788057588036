.bottomMenu__bar {
  background-image: url('/assets/nav-bar-drawer.png');
  background-position: center;
  height: 20px;
  cursor: ns-resize;
}

.bottomMenuPadding {
  width:100%;
  height: 10px;
  //background-color: $primary-color;
  z-index: 101;
  position: fixed;
  bottom: 0;
  background-image: url('/assets/nav-base-panel.png');
  background-position: 0px 44px;
  @include media-breakpoint-up(sm){
    background-position: 0px 44px;
  }
  @include media-breakpoint-up(lg){
    background-position: bottom;
  }
    //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      height: 20px;
      background-position: 0px 44px;
  }
  
}