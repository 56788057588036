html {
  font-size: $font-size-mobile-main;

  // @include media-breakpoint-up(sm){
  //   font-size : $font-size-mid-desktop;
  // }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-desktop-main;
  }
}

body {
  //font-family: Verdana, Geneva, Tahoma, sans-serif;
  min-height: 100vh;
  background: $bg-color;
  font-weight: $weight-normal;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
