.slick-active .banner-item__title__animation {
  -webkit-animation: tracking-in-contract-bck 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.banner-item__des {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: normal;
}
.slick-active .banner-item__des__animation {
  -webkit-animation: fade-in-bottom 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.banner-item__link2 {
  font-size: 1.2rem;
  font-weight: 800;
  display: inline-block;
  background-color: $primary-color;
  color: #fff;
  margin-top: 1rem;
  padding: 0.2rem 1rem;
  border-radius: 30px;
  padding-bottom: 0.34rem;
  text-transform: uppercase;
  &:hover,
  &:focus {
    color: #fff;
    background-color: $link-hover-color;
    text-decoration: none;
  }
}
.banner_item__text2 {
  text-align: center;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  top: calc(50% + 22px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
  z-index: 10;
  font-family: $family-base;
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.1;

  @include media-breakpoint-up(lg) {
    top: calc(50% + 28px);
    font-size: 2.3rem;
    margin-bottom: -0.3rem;
  }
}
