.navBar {
  position: fixed;
  //top: map-get($map: $top-bar-height, $key: mobile);
  top: 2.45rem;
  width: 90%;
  max-width: 400px;
  height: auto;
  //background-color: rgba($color-green, 0.3);
  background-color: $color-green;
  color: white;
  z-index: 999;
  opacity: 0.3;
  transition: $transition-base;
  transform: translateX(100%);
  right: 0;
  
  @include media-breakpoint-up(sm){
    //top: map-get($map: $top-bar-height, $key: tablet);
    top: 3.1rem;
  }
  
  @include media-breakpoint-up(lg) {
    //max-width: 300px;
    //top: map-get($map: $top-bar-height, $key: desktop);
    top: 2.8rem;
  }
  
  // .scrolled &,
  // .subpage & {
  //   top: map-get($map: $top-bar-height, $key: mobile);

  //   @include media-breakpoint-up(lg) {
  //     top: map-get($map: $top-bar-height, $key: desktop);
  //   }
  // }
}

.navBar--on {
  transform: translateX(0);
  opacity: 1;
}

// navBar menu list common
.navBar_nav {
  margin: 0;

  ul,
  li {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
  }

  li {
    position: relative;
    margin: 0.5rem 0;
  }

  a {
    display: block;
    color: $color-blue;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1;
    transition-duration: 0.3s;
    text-transform: uppercase;
    background-color: rgba(#fff, 0.9);

    &:hover,
    &:focus {
      text-decoration: none;
      color: white;
      background-color: $primary-color;
    }
  }
}

div.call-in-menu{
  margin-top: 0.5rem;
  padding-left: 15px;
  padding-right: 15px;
  //margin-bottom: 0.5rem;
  a{
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    font-size: 1rem;
    padding: 1rem;
    //border: 1px solid #999;
    border-radius: 20px;
    display: block;
    position: relative;
    color: #30588c;
    &:hover{
      text-decoration: none;
    }
    span.call-us-text{
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      //padding-top: 4px;
      font-size: 1.5rem;
      font-weight: 800;
      line-height: 1;
    }
    .call-us-img {
      max-width: 28px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 6%;
      @include media-breakpoint-up(lg){
        max-width: 32px;
      }
      img{
        width: 100%;
      }
    }
  }
}