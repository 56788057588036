b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;

  &:hover,
  &:focus,
  &:active {
    //color: darken($primary-color, 5%);
    color: $link-hover-color;
    //text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $family-header;
  font-weight: $weight-normal;
  color: $header-color;
  line-height: 1.2;
  margin: 0.5em 0 0.3em;
}

h1 {
  margin-top: 1rem;
  b, strong{
    color: $color-green;
    font-weight: $weight-bold;
  }
}

h2 {
  font-weight: $weight-regular;
  b, strong{
    color: $color-green;
    font-weight: $weight-bold;
  }
}

h3 {
  font-weight: $weight-light;
  b, strong{
    color: $color-blue;
  }
}

h4 {
  letter-spacing: 1px;
  font-weight: $weight-light;
}

h5 {
}

// size
// h1 {
//   font-size: rem(60, $font-size-mobile-main);
// }

// h2 {
//   font-size: rem(50, $font-size-mobile-main);
// }

// h3 {
//   font-size: rem(40, $font-size-mobile-main);
// }

// h4 {
//   font-size: rem(30, $font-size-mobile-main);
// }

// h5 {
//   font-size: rem(19, $font-size-mobile-main);
// }

h1 {
  //font-size: rem(35, $font-size-mobile-main);
  font-size: rem(30, $font-size-mobile-main);
}

h2 {
  //font-size: rem(35, $font-size-mobile-main);
  font-size: rem(30, $font-size-mobile-main);
}

h3 {
  //font-size: rem(22, $font-size-mobile-main);
  font-size: rem(24, $font-size-mobile-main);
}

h4 {
  //font-size: rem(20, $font-size-mobile-main);
  font-size: rem(22, $font-size-mobile-main);
}

h5 {
  font-size: rem(14, $font-size-mobile-main);
}

// @include media-breakpoint-up(sm){
//   h1 {
//     font-size: rem(120, $font-size-mid-desktop);
//   }

//   h2 {
//     font-size: rem(60, $font-size-mid-desktop);
//   }

//   h3 {
//     font-size: rem(40, $font-size-mid-desktop);
//   }

//   h4 {
//     font-size: rem(30, $font-size-mid-desktop);
//   }

//   h5 {
//     font-size: rem(22, $font-size-mid-desktop);
//   }

// }

@include media-breakpoint-up(lg) {

  // h1 {
  //   font-size: rem(160, $font-size-desktop-main);
  // }

  // h2 {
  //   font-size: rem(80, $font-size-desktop-main);
  // }

  // h3 {
  //   font-size: rem(48, $font-size-desktop-main);
  // }

  // h4 {
  //   font-size: rem(36, $font-size-desktop-main);
  // }

  // h5 {
  //   font-size: rem(24, $font-size-desktop-main);
  // }
  
  h1 {
    font-size: rem(45, $font-size-desktop-main);
  }

  h2 {
    font-size: rem(40, $font-size-desktop-main);
  }

  h3 {
    //font-size: rem(24, $font-size-desktop-main);
    font-size: rem(27, $font-size-desktop-main);
  }

  h4 {
    //font-size: rem(22, $font-size-desktop-main);
    font-size: rem(25, $font-size-desktop-main);
  }

  h5 {
    font-size: rem(16, $font-size-desktop-main);
  }
}


// html editor
.text-tiny {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
}