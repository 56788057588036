.main-content {
  //padding-top: map-get($top-bar-height, mobile);
  padding-top: 0px;
  // @include media-breakpoint-up(lg) {
  //   padding-top: map-get($top-bar-height, desktop);
  // }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  color: white;
  button {
    margin-right: 0.7rem;
    border-radius: 30px;
    font-weight: $weight-bold;
  }
}