@import "standard";
@import "small";
@import "thin";
@import "thick";

.bannerSize,
.bannerSize--standard {
  @extend %bannerSize--standard;
}

.bannerWrapperSize--thin .bannerSize,
.bannerSize--thin {
  @extend %bannerSize--thin;
}

// not used
.bannerWrapperSize--small .bannerSize,
.bannerSize--small {
  @extend %bannerSize--small;
}

.bannerWrapperSize--thick .bannerSize,
.bannerSize--thick {
  @extend %bannerSize--thick;
}
